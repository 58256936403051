import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';
import { Container, Row, Col } from 'react-bootstrap';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import LinkButton from '../LinkButton/LinkButton';
import * as styles from './collaboration.module.scss';

const Collaboration = ({ collaboration }) => {
  const interview = collaboration.interview;
  const image = collaboration.image;
  const intl = useIntl();

  return (
    <Container>
      <Row className={styles.section}>
        <Col md={6}>
          {interview.map((report, index) => (
            <div key={index}>
              <p className="font-weight-bold">{report.question}</p>
              <p
                style={{ marginBottom: index === interview.length - 1 && '0' }}
              >
                {report.answer}
              </p>
            </div>
          ))}
          <Row>
            <Col className={styles.buttonColumn}>
              <p className="button">
                <LinkButton
                  path="/contact"
                  title={intl.formatMessage({ id: 'button.create_together' })}
                />
              </p>
            </Col>
          </Row>
        </Col>
        <Col md={6} className="text-center">
          <GatsbyImage
            image={getImage(image)}
            alt={collaboration.imageAlt}
            className="image"
          />
        </Col>
      </Row>
    </Container>
  );
};

export default Collaboration;
