import * as React from 'react';
import { graphql } from 'gatsby';
import { useIntl } from 'gatsby-plugin-intl';
import Layout from '../../components/Layout';
import SEO from '../../components/SEO/SEO';
import Header from '../../components/Header/Header';
import Collaboration from '../../components/Collaboration/Collaboration';
import BreadCrumbs from '../../components/BreadCrumbs/BreadCrumbs';
import * as styles from '../../components/Header/header.module.scss';
import { LineIcon } from '../../images/index';

const CollaborationPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;
  const seoData = {
    markdownRemark: {
      frontmatter: {
        en: data.markdownRemark.frontmatter.en.seo,
        hu: data.markdownRemark.frontmatter.hu.seo
      }
    }
  };
  const intl = useIntl();
  const langData = intl.locale === 'hu' ? frontmatter.hu : frontmatter.en;
  const crumbs = ['home', langData.heading];

  return (
    <Layout>
      <SEO seoData={seoData} />
      <Header>
        {langData.heading.split('\n\n').map((line, index) => (
          <h1 className={styles.headerTitle} key={index}>
            {line}
          </h1>
        ))}
        <LineIcon className={styles.lineIcon} />
      </Header>
      <BreadCrumbs crumbs={crumbs} />
      <Collaboration collaboration={langData} />
    </Layout>
  );
};
export default CollaborationPage;

export const pageQuery = graphql`
  query CollaborationPageTemplate {
    markdownRemark(fileAbsolutePath: { regex: "/collaboration.md/" }) {
      frontmatter {
        en {
          heading
          interview {
            question
            answer
          }
          image {
            childImageSharp {
              gatsbyImageData(height: 723)
            }
          }
          imageAlt
          seo {
            seoTagList {
              seoTagKey
              seoTagValue
            }
            siteTitle
            siteDescription
          }
        }
        hu {
          heading
          interview {
            question
            answer
          }
          image {
            childImageSharp {
              gatsbyImageData(height: 723)
            }
          }
          imageAlt
          seo {
            seoTagList {
              seoTagKey
              seoTagValue
            }
            siteTitle
            siteDescription
          }
        }
      }
    }
  }
`;
